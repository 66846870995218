import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotesList = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC';
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get('/api/notes', { headers });
      const formattedData = response.data.map(note => ({
        ...note,
        date: new Date(note.date).toLocaleString()
      }));
      setNotes(formattedData);
      setError('');
    } catch (err) {
      console.error('Full Error Object:', err);
      const errorMessage = err.response 
        ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
        : `Error: ${err.message}\nStack: ${err.stack}`;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC';
        const headers = {
          'Authorization': `Bearer ${token}`
        };

        await axios.delete(`/api/notes/${id}`, { headers });
        // Refresh the notes list
        fetchNotes();
      } catch (err) {
        console.error('Full Error Object:', err);
        const errorMessage = err.response 
          ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
          : `Error: ${err.message}\nStack: ${err.stack}`;
        setError(errorMessage);
      }
    }
  };

  if (loading) {
    return <div className="notes-loading">Loading notes...</div>;
  }

  return (
    <div className="notes-list-container">
      <h2 className="notes-list-title">Notes List</h2>
      
      {error && (
        <div className="notes-error" style={{ whiteSpace: 'pre-wrap' }}>
          {error}
        </div>
      )}
      
      {notes.length === 0 ? (
        <div className="no-notes">No notes found</div>
      ) : (
        <div className="table-container">
          <table className="notes-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {notes.map(note => (
                <tr key={note.id}>
                  <td>{note.date}</td>
                  <td className="amount">${parseFloat(note.amount).toFixed(2)}</td>
                  <td>{note.notes}</td>
                  <td>
                    <button 
                      className="delete-button"
                      onClick={() => handleDelete(note.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NotesList;