import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotesCSVExport = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC';
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get('/api/notes', { headers });
      const formattedData = response.data.map(note => ({
        ...note,
        date: new Date(note.date).toLocaleString()
      }));
      setNotes(formattedData);
      setError('');
    } catch (err) {
      console.error('Full Error Object:', err);
      const errorMessage = err.response 
        ? `Error: ${err.response.status} - ${err.response.statusText}\n${JSON.stringify(err.response.data, null, 2)}`
        : `Error: ${err.message}\nStack: ${err.stack}`;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const generateCSV = () => {
    // Create CSV header
    const csvHeader = ['Date', 'Amount', 'Notes'].join('|');
    
    // Create CSV rows
    const csvRows = notes.map(note => {
      return [
        note.date,
      `$${parseFloat(note.amount).toFixed(2)}`,
      // Since we're using pipes, we only need to escape pipes in the notes
      note.notes.replace(/\|/g, '\\|')
    ].join('|');
    });
    
    // Combine header and rows
    const csvString = [csvHeader, ...csvRows].join('\n');
    
    // Create and trigger download
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'notes_export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const styles = {
    container: {
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
    },
    title: {
      color: '#333',
      marginBottom: '20px',
    },
    csvContent: {
      backgroundColor: '#f5f5f5',
      padding: '15px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontFamily: 'monospace',
      overflowX: 'auto',
      marginBottom: '20px',
    },
    row: {
      display: 'flex',
      borderBottom: '1px solid #eee',
      padding: '8px 0',
    },
    cell: {
      flex: 1,
      padding: '0 10px',
    },
    header: {
      fontWeight: 'bold',
      backgroundColor: '#eee',
      padding: '10px',
      marginBottom: '10px',
    },
    downloadButton: {
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
    },
    error: {
      color: '#ff0000',
      padding: '10px',
      backgroundColor: '#ffebee',
      borderRadius: '4px',
      marginBottom: '20px',
    },
    loading: {
      color: '#666',
      fontSize: '16px',
      textAlign: 'center',
      padding: '20px',
    }
  };

  if (loading) {
    return <div style={styles.loading}>Loading notes...</div>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Notes CSV Export</h2>
      
      {error && (
        <div style={styles.error}>
          {error}
        </div>
      )}

      {notes.length === 0 ? (
        <div>No notes found</div>
      ) : (
        <>
          <div style={styles.csvContent}>
            <div style={styles.header}>
              <div style={styles.row}>
                <div style={styles.cell}>Date</div>
                <div style={styles.cell}>Amount</div>
                <div style={styles.cell}>Notes</div>
              </div>
            </div>
            {notes.map(note => (
              <div key={note.id} style={styles.row}>
                <div style={styles.cell}>{note.date}</div>
                <div style={styles.cell}>${parseFloat(note.amount).toFixed(2)}</div>
                <div style={styles.cell}>{note.notes}</div>
              </div>
            ))}
          </div>
          
          <button 
            onClick={generateCSV}
            style={styles.downloadButton}
          >
            Download CSV
          </button>
        </>
      )}
    </div>
  );
};

export default NotesCSVExport;