import React, { useState } from 'react';
import axios from 'axios';

const Notes = () => {
  const [formData, setFormData] = useState({
    date: new Date().toISOString().slice(0, 16),
    amount: '',
    notes: ''
  });
  
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setError('');
    setSuccess(false);
    
    if (name === 'amount' && isNaN(value)) {
      setError('Amount must be a number');
      return;
    }
    
    if (name === 'notes' && value.length > 100) {
      setError('Notes cannot exceed 100 characters');
      return;
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.amount) {
      setError('Amount is required');
      return;
    }
    
    if (!formData.notes) {
      setError('Notes are required');
      return;
    }

    try {
      const token = '2TidvzmZ0UJh08CEu98g75TPCnR82GgC'; // Replace with your actual token
          const headers = {
          Authorization: `Bearer ${token}`
          };

      // Use relative path, matching your working stations endpoint pattern
      const response = await axios.post('/api/notes', formData, { headers });
      
      console.log('Success:', response.data);
      setSuccess(true);
      setFormData({
        date: new Date().toISOString().slice(0, 16),
        amount: '',
        notes: ''
      });
    } catch (error) {
      console.error('=== Client Error Details ===');
      console.error('Error Object:', error);
      console.error('Error Config:', error.config);
      console.error('Error Message:', error.message);
      console.error('Error Stack:', error.stack);
      console.error('Error Response:', error.response);
      console.error('==========================');
      
      let errorDetails = [];
      errorDetails.push(`Error Message: ${error.message}`);
      errorDetails.push(`Stack Trace: ${error.stack}`);
      
      if (error.response) {
        errorDetails.push(`Status: ${error.response.status}`);
        errorDetails.push(`Status Text: ${error.response.statusText}`);
        errorDetails.push(`Response Data: ${JSON.stringify(error.response.data, null, 2)}`);
      }

      if (error.config) {
        errorDetails.push(`Request URL: ${error.config.url}`);
        errorDetails.push(`Request Method: ${error.config.method}`);
        errorDetails.push(`Request Headers: ${JSON.stringify(error.config.headers, null, 2)}`);
        errorDetails.push(`Request Data: ${JSON.stringify(error.config.data, null, 2)}`);
      }

      setError(errorDetails.join('\n\n'));
    }
};



  return (
    <div className="notes-container">
      <div className="notes-form-wrapper">
        <h2 className="notes-title">Add Note</h2>
        
        <form onSubmit={handleSubmit} className="notes-form">
          <div className="form-group">
            <label>Date and Time</label>
            <input
              type="datetime-local"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="form-group">
            <label>Amount</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter amount"
              step="0.01"
              required
            />
          </div>
          
          <div className="form-group">
            <label>Notes</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Enter notes (max 100 characters)"
              maxLength={100}
              rows={3}
              required
            />
            <div className="char-count">
              {formData.notes.length}/100 characters
            </div>
          </div>

          {error && (
    <div className="error-message" style={{ 
        whiteSpace: 'pre-wrap',
        overflowX: 'auto',
        maxHeight: '300px',
        overflowY: 'auto',
        padding: '15px',
        fontFamily: 'monospace',
        fontSize: '12px',
        lineHeight: '1.4'
    }}>
        {error}
            </div>
          )}
          
          {success && (
            <div className="success-message">
              Note added successfully!
            </div>
          )}

          <button type="submit" className="submit-button">
            Save Note
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notes;